@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.upd-btn {
  cursor: pointer;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.74);
  font-size: 14px;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.74);
  font-weight: 600;
}

.upd-btn:hover {
  opacity: 0.8;
}
.call-btn {
  cursor: pointer;
  background-color: rgb(7, 115, 152);
  font-size: 14px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: 600;
}

.text_blue {
  color: rgb(43, 131, 203);
}

.text_red {
  color: rgb(255, 0, 0);
}

.login_btn {
  cursor: pointer !important;
  background-color: rgb(7, 115, 152) !important;
  font-size: 24px !important;
  border: none;
  border-radius: 5px !important;
  color: white !important ;
  font-weight: 600;
}

.call-btn:hover {
  opacity: 0.9;
}

.number-input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.number-input input[type="number"] {
  -moz-appearance: textfield;
}

.number-input {
  height: 40px;
  width: 200px;
}

.search-result {
  height: 40px;
  width: 200px !important;
}

.callerid-text {
  font-weight: 600;
  color: #4a4a4a;
  font-size: 24px !important;
}

/* #4a4a4a */
.header-box {
  background-color: #4a4a4a;
  color: white;
  /* font-family: "Poppins"; */
  font-weight: 500;
}

.py_10 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.country-select {
  height: 40px;
  width: 200px;
}

@media (max-width: 576px) {
  #client_dropdown {
    height: 40px;
    width: 100% !important;
  }
  .search-result {
    height: 40px;
    width: 100% !important;
  }
}

#client_dropdown {
  height: 40px;
  width: 270px;
}

.no_result_text {
  white-space: nowrap;
  font-size: 24px;
  color: #3a3939;
}

.makeStyles-img-29 {
  width: 190px !important;
  min-height: 60px !important;
}
.makeStyles-root-25 {
  height: 90px !important;
}

.call-box-shadow {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  height: 100px;
  width: 100%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
  cursor: pointer;
  transition: 0.3s ease;
}

.call-box-shadow:hover {
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.09);
}
.call-box-shadow p {
  font-weight: 600;
  color: #4a4a4a;
  font-size: 24px !important;
}

@media (max-width: 576px) {
  .MuiGrid-container {
    display: flex;
    flex-direction: column;
  }
  .call-box-shadow p {
    font-weight: 600;
    color: #4a4a4a;
    font-size: 15px !important;
  }
}

.min_h_box {
  min-height: 30vh;
}
